// Changing the Column Count through theme provider
import React, {useState} from 'react';
import {Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text, ThemeProvider, Image, Row, Col, Container} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';
import {useDropzone} from 'react-dropzone'
import {Progress, Tab} from 'semantic-ui-react'

// const theme = {
//     colCount: 12
// };

const theme = {
  grid: {
    containerWidth: {
        xs: "540px",
        sm: "720px",
        md: "960px",
        lg: "1156px",
        xl: "1156px"
    },
    gutterWidth: "12px",
  }
};


const BasicModal = (props) => {

    let width = 50;
    const theme = {
        grid : {
            colCount: 2,
            containerWidth: {
                xs: "540px",
                sm: "720px",
                md: "720px",
                lg: "700px",
                xl: "700px"
            },
            gutterWidth: "10px",
        },
        fontFamily: {
    primary: "equity-text, serif",
  },
  textSize: {
    size: {
      customSize: "125px"
    },
    height: {
      customSize: "134px"
    }
  }
    }
    return <StyletronProvider value={props.engine}>
        <ThemeProvider theme={theme}>
            <Container>
              <Row>
                  <Col size={1} >asdf</Col>
                  <Col size={1} >fda</Col>
              </Row>
            </Container>

            {/*<Container m={{b: "4rem"}} textAlign={"center"}>*/}
            {/*    <Row>*/}
            {/*        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (*/}
            {/*            <Col size={1} key={index}>*/}
            {/*                /!*<Div bg="warning800" h="3rem" rounded="md" w="5rem"/>*!/*/}
            {/*                <Text>a</Text>*/}
            {/*            </Col>*/}
            {/*        ))}*/}
            {/*    </Row>*/}

            {/*    /!*<Row p={{t:"30px"}}>*!/*/}
            {/*    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (*/}
            {/*        <Row>*/}
            {/*            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (*/}
            {/*                <Col size={1} key={index}>*/}
            {/*                    /!*<Div bg="warning800" h="3rem" rounded="md" w="5rem"/>*!/*/}
            {/*                    <Text>a</Text>*/}
            {/*                </Col>*/}
            {/*            ))}*/}
            {/*        </Row>*/}
            {/*    ))}*/}
            {/*    /!*</Row>*!/*/}
            {/*</Container>*/}
        </ThemeProvider>
    </StyletronProvider>
}


export default {BasicModal};
export {BasicModal};
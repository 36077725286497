import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import {Div, StyleReset, Text, ThemeProvider} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';

let starup = new Array(20).fill(true)

function to_series(data, gpus) {
    const series = {}
    let names = new Set()
    data.forEach((ele, idx) => {
        names.add(ele.user);
    })
    names.forEach((name, _) => {
        series[name] = {
            name: name,
            type: 'bar',
            stack: 'users',
            data: new Array(gpus).fill(0),
            pid: Array.from(Array(gpus), () => []),
            emphasis: {focus: 'series'},
            error: new Array(gpus).fill(''),
        }
    })
    data.forEach((e, idx) => {
        let s = series[e.user];
        s.data[e.index] += e.used_memory;
        s.pid[e.index].push([e.pid, e.used_memory]);
        s.error[e.index] = e.error;
    })
    const new_series = Object.values(series);
    if (new_series.length === 0) {
        const new_series2 = [{
            name: '',
            type: 'bar',
            stack: 'user',
            data: new Array(gpus).fill(0),
            pid: Array.from(Array(gpus), () => []),
            emphasis: {focus: 'series'},
            error: new Array(gpus).fill('') 
        }];
        return new_series2
    }else{
        return new_series
    }
}



const GPUCard = (props) => {
    let ref = null;
    const [ip, setIP] = useState("");
    const [admin, setAdmin] = useState("");
    const [option, setOption] = useState({});
    const [alive, setAlive] = useState(false);
    const [error, setError] = useState(0);
    const [disk, setDisk] = useState({'home':0, 'data':0});


    const collect_error = (json_data)=>{
            let error_gpus = new Set();
            json_data.usage.forEach((usage, i)=>{
                if(usage.error.length > 0){
                    error_gpus.add(usage.index);
                }
            })
            let error = '';
            if(error_gpus.size > 0){
                error = 'Error in GPU [' + Array.from(error_gpus).join(', ') + '] !!!'; 
            }
            return error;
        }

    const fetch_data = () => {
        fetch(props.root + '/api/gpu/' + (props.idx))
            .then((response) => response.json())
            .then((data2) => {
                let json_data = JSON.parse(data2);
                setIP(json_data.ip);
                let error = collect_error(json_data);
                setError(error);
                setAdmin(json_data.admin);
                setDisk(json_data.disk);
                let series = to_series(json_data.usage, json_data.gpu_num);
                // let errors = new Array(json_data.length)
                set_option(series, json_data.gpu_num, json_data.total_memory);
                setAlive(json_data.alive);
            })
            .catch((exception) => {
                console.log('fetch data failed', exception);
                setIP("Error");
            });
    }

    const set_option = (series, gpu_num, total_memory) => {
        const DEFAULT_OPTION = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    let used_memory;
                    if (params.length > 1) {
                        used_memory = params.reduce((pre, cur) => (pre + cur.value), 0);
                    } else {
                        used_memory = params[0].value;
                    }
                    if (used_memory === 0) {
                        let ret = [`<div style="text-align: center; font-weight: 700;"> Empty </div>`];
                        if(series[params[0].componentIndex].error[params[0].dataIndex].length > 0){
                            ret.push('</br>');
                            series[params[0].componentIndex].error[params[0].dataIndex].split('\n').forEach((error, i) =>{
                                ret.push(`<div style="text-align: center; font-weight: 700;"> ${error} </div>`);
                            })
                        }
                        return ret.join('');
                    }
                    let ret = [];
                    params.forEach((param, idx) => {
                        try {
                            let pids = series[param.componentIndex].pid[param.dataIndex];
                            if (pids.length > 0) {
                                let s = '';
                                s += `<div style="text-align: center; font-weight: 700;">${param.seriesName}</div>`;
                                pids.forEach((pid, idx) => {
                                    s += `<div>pid (${pid[0]}) : ${pid[1]} M</div>`;
                                })

                                ret.push(s);
                                if(series[param.componentIndex].error[param.dataIndex].length > 0){
                                    ret.push('</br>')
                                    series[param.componentIndex].error[param.dataIndex].split('\n').forEach((error, i) =>{
                                        ret.push(`<div style="text-align: center; font-weight: 700;"> ${error} </div>`);
                                    })
                                }
                            }
                        } catch (e) {
                            console.log('WHAT????');
                        }
                    })
                    return ret.join('');
                }
            },
            grid: {
                left: '13%',
            },
            legend: {
                top: 0,
                bottom: 0
            },
            xAxis: [
                {
                    type: 'category',
                    data: Array.from({length: gpu_num}, (v, k) => k)
                }
            ],
            yAxis: [
                {
                    max: total_memory,
                    type: 'value'
                }
            ],
            series: series
        };
        setOption(DEFAULT_OPTION);
    }

    useEffect(() => {
        let timer;
        if(starup[props.idx]){
            timer = setTimeout(() => {fetch_data()}, 500);
            starup[props.idx] = false;
        }else{
            timer = setTimeout(() => {fetch_data()}, props.update_freq);
        }
        return () => {
            clearTimeout(timer);
        }
    });

    return <Div rounded='md' m='8px' p="10px" hover="3" className="my-card" w={props.width + "px"} h={props.height + 40 + "px"}>
        {
            alive ? <ReactECharts notMerge={true} option={option} style={{width: props.width - 20, height: props.height - 30}}/>
                : <Div d="flex" flexDir="column" justify="center" align="center" style={{width: props.width - 20, height: props.height - 30}}>
                    <Text textSize="display1" justify="center" align="center" textWeight={500}> Not Connected </Text>
                </Div>
        }

        <Div m={{t: "-40px"}} textAlign='center'>
            {/***** IP ******/}
            {(ip === "Error" || !alive) ?
                <Text bg="danger800" d="inline-block" rounded='circle' w="10px" h="10px"></Text> :
                <Text bg="success600" d="inline-block" rounded='circle' w="10px" h="10px"></Text>
            }
            {"  "}
            {(ip === "Error") ?
                <Text textSize='paragraph' textWeight={500} textAlign='center' d="inline-block"> ERROR </Text> :
                <Text textSize='paragraph' textWeight={500} textAlign='center' d="inline-block"> IP : {ip}</Text>
            }

            {/***** Admin ******/}
            <Text textSize='paragraph' textWeight={500} m={{t: '-3px'}}>Admin : {admin}</Text>

            {/***** Disk ******/}
            <Text textSize='paragraph' textWeight={500} m={{t: '-3px'}} d="inline-block">Home : {disk['home'].toFixed(0)} G,</Text> {' '}
            <Text textSize='paragraph' textWeight={500} m={{t: '-3px'}} d="inline-block">Data : {disk['data'].toFixed(0)} G</Text>

            {/***** Error ******/}
            {(error.length > 0)?
            <Text textSize='paragraph' textWeight={500} m={{t: '-3px'}} textColor={"danger800"} > {error}</Text> :
            <Text textSize='paragraph' textWeight={500} m={{t: '-3px'}} textColor={"success500"} >  </Text>
            }
        </Div>
    </Div>
};


const ServerList = (props) => {
    console.log(props.root)
    const [time, setTime] = useState({time:""})

    const fetch_time = () => {
        fetch(props.root + '/api/gpu/time')
            .then((response) => response.json())
            .then(data=>setTime(data))
            .catch((exception) => {
                console.log('set time failed', exception);
            });
    }

    useEffect(() => {
        let timer;
        if(starup[starup.length-1]){
            timer = setTimeout(() => {fetch_time()}, 500);
            starup[starup.length-1] = false;
        }else{
            timer = setTimeout(() => {fetch_time()}, props.update_freq);
        }
        return () => {
            clearTimeout(timer);
        }
    });

    let width = 400, height = 300;
    let card_idx = Array.from({length: props.server_num}, (v, k) => k)
    return <StyletronProvider value={props.engine}>
        <ThemeProvider>
            <StyleReset/>
            <Div
                d="flex"
                flexDir="column"
                justify="center"
                align="center">
                <Text
                    tag='h1'
                    textColor="black900"
                    d="flex"
                    flexDir="column"
                    justify="center"
                    align="center"
                    textSize="display2"
                    fontFamily="secondary"
                    textWeight="500"
                    p={{t: "1rem", b: "0.5rem"}}
                    border={{b: "1px solid"}}
                    borderColor="gray400">
                    Usage of Servers
                </Text>
                <Text textSize="subheader" p={{t: "10px", b: "0px"}} textColor={"gray900"}>The utilization of each server
                    in <strong>MedAI</strong> Group.</Text>

                <Text textSize="body" p={{t: "0px", b: "15px"}} textColor={"gray900"}>(Last update : {time.time})</Text>

                <Div d="flex" justify="flex-start" flexWrap="wrap" flexDir="row">
                    {card_idx.map((ele, idx) =>
                        <GPUCard width={width} height={height} idx={idx} key={idx} root={props.root} update_freq={props.update_freq}/>
                        )}
                </Div>
            </Div>
        </ThemeProvider>
    </StyletronProvider>
}

const exported_obj = {ServerList};
export default exported_obj;
export {ServerList as Server};
import React, {useState} from 'react';
import {
    Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text,
    ThemeProvider, Image, Dropdown, currentDevice
} from "atomize";
import {useDropzone} from 'react-dropzone'
import {Progress} from 'semantic-ui-react'
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';

import img_nju from '../imgs/NJU.jpg'
import img_njucs from '../imgs/NJU_CS.png'
import img_medai from '../imgs/NJUMedAI.png'
import img_gulou from '../imgs/gulou.png'

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


const LinePrediction = (props) => {
    const [result, setResult] = useState("");
    const [showModal, setShow] = useState(false);
    const [msg, setMsg] = useState("");

    const collect_data = (results) => {
            let age = parseFloat(document.getElementById('age').value);
            let height = parseFloat(document.getElementById('height').value);
            let pro = parseFloat(document.getElementById('pro').value);
            let Cr = parseFloat(document.getElementById('Cr').value);
            let SOFA = parseFloat(document.getElementById('SOFA').value);
            let NUTRIC = parseFloat(document.getElementById('NUTRIC').value);
            let ISS = parseFloat(document.getElementById('ISS').value);
            let SMI = parseFloat(document.getElementById('SMI').value);
            let CRP= parseFloat(document.getElementById('CRP').value);


            if (isNaN(age) || isNaN(height) || isNaN(SMI) || isNaN(pro) || isNaN(Cr) ||
                isNaN(NUTRIC) || isNaN(CRP) || isNaN(Cr) || isNaN(SOFA) || isNaN(ISS)) {
                setMsg("数据有误!")
                setShow(true);
                return false;
            }
            results.push(age);
            results.push(height);
            results.push(pro);
            results.push(Cr);
            results.push(SOFA);
            results.push(NUTRIC);
            results.push(ISS);
            results.push(SMI);
            results.push(CRP);
            return true
        }

    const send_data = () => {
       // let word = document.getElementById('word').value.trim();
        let result = []
        if (collect_data(result)) {
            fetch(props.root + `/api/cal/process_data` , {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    'data': result
                })
            })
                .then(result => result.json())
                .then(data => {
                    console.log(data);
                    setResult(data['result']);
                })
                .catch(error => error);
        }
    }

    const onClose = () => {
        setShow(false);
    };

    return <Div align='center' textAlign='center'>
        <Div d="flex" align='center' textAlign='center'>
            <Input placeholder="年龄" h="3rem" textSize="subheader" m={{r: "10px"}} id="age"/>
            <Input placeholder="身高 (m)" h="3rem" textSize="subheader" m={{r: "10px"}} id="height"/>
            <Input placeholder="日均蛋白（g/天）" h="3rem" textSize="subheader" m={{r: "10px"}} id="pro"/>
            </Div>
        <Text textSize="title" m={{b: "15px"}} textWeight={200}></Text>
        <Div d="flex" align='center' textAlign='center'>
            <Input placeholder="Cr" h="3rem" textSize="subheader" m={{r: "10px"}} id="Cr"/>
            <Input placeholder="SOFA" h="3rem" textSize="subheader" m={{r: "10px"}} id="SOFA"/>
            <Input placeholder="NUTRIC" h="3rem" textSize="subheader" m={{r: "10px"}} id="NUTRIC"/>
            </Div>
        <Text textSize="title" m={{b: "15px"}} textWeight={200}></Text>
        <Div d="flex" align='center' textAlign='center'>
            <Input placeholder="ISS" h="3rem" textSize="subheader" m={{r: "10px"}} id="ISS"/>
            <Input placeholder="SMI" h="3rem" textSize="subheader" m={{r: "10px"}} id="SMI"/>
            <Input placeholder="CRP" h="3rem" textSize="subheader" m={{r: "10px"}} id="CRP"/>
            </Div>
        <Text textSize="title" m={{b: "15px"}} textWeight={300}></Text>
        <Div d="flex" align='center' textAlign='center'>
            <Text textSize="title" p={{t: "5rem"}} m={{l: "510px"}} textWeight={300}></Text>
            <Button
                h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={send_data}
            >热卡预测</Button>
        </Div>

        <Div d="flex" p={{t: "15px"}}>
            <Text p={{r: "20px", l:"5px"}} textWeight={500} textSize="subheader">预测结果： </Text>
        </Div>
        <Div d="flex" justify-content= "flex-start" p={{t: "15px"}}>
            <Text textColor="#000000" textWeight={500} textAlign={'left'} textSize="subheader">{result}</Text>
        </Div>

        <Modal isOpen={showModal} onClose={onClose} align="center" rounded="md">
            <Icon name="Cross" pos="absolute" top="1rem" right="1rem" size="16px" onClick={onClose} cursor="pointer"/>
            <Div d="flex" m={{b: "1rem"}}>
                <Icon name="AlertSolid" color="warning700" m={{t: "0.15rem", r: "0.5rem"}}/>
                <Text p={{l: "0.5rem", t: "0.25rem"}} textSize="subheader">
                    {msg}
                </Text>
            </Div>
            <Div d="flex" justify="flex-end">
                <Button onClick={onClose} bg="info700">
                    确认
                </Button>
            </Div>
        </Modal>
    </Div>
}


const CAL = (props) => {
    let width = 32;
    let logo_width = 90;

    return <StyletronProvider value={props.engine}>
        <ThemeProvider>
            <StyleReset/>

            <Div d="flex" flexDir="column" justify="center" align="center" m={{b: "10px"}}>
                <Text
                    textColor="black900"
                    textSize="display2"
                    textWeight="500"
                    p={{t: "1rem", b: "0.5rem"}}
                    border={{b: "1px solid"}}
                    borderColor="gray400">
                    重症患者恢复期个体化营养剂量AI计算模型(热卡)
                </Text>

                <Text textSize="subheader" textWeight="600" p={{t: "5px", b: "15px"}} textColor={"gray900"}>测试平台</Text>
            </Div>

            <Div d="flex" flexDir="column" justify="center" align="center">
                <Div w="45rem">
                    <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md" m={{b: "30px"}}>
                        <Text textSize="title" m={{b: "15px", l: "10px"}} textWeight={500}>临床数据：</Text>
                        <Div d="flex" flexDir="column" m={{l: "10px"}} justify="center" align="center">
                            <LinePrediction width={width} root={props.root}/>
                        </Div>
                    </Div>
                </Div>

                    <Div d="flex" flexDir="row" justify="center" align="center" m={{t: "2rem", l: "1rem"}}>
                        <Image src={img_gulou} h={logo_width + "px"} w={(logo_width /0.9) + "px"} m={{r: "30px"}}/>
                        <Image src={img_nju} h={logo_width + "px"} w={(logo_width / 1.2) + "px"} m={{r: "30px"}}/>
                        <Image src={img_njucs} h={logo_width + "px"} w={(logo_width - 20) + "px"} m={{r: "30px"}}/>
                        <Image src={img_medai} h={logo_width + "px"} w={logo_width + "px"} />
                    </Div>
            </Div>

        </ThemeProvider>
    </StyletronProvider>
}

const exported_object = {CAL}
export default exported_object;
export {CAL}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: -apple-system, STKaiti, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.author {
    /*font-weight: 500;*/
    text-align: center;
    padding: 0;
    /*margin: -40px 0px 0px 0px;*/
}

/*.author>p{*/
/*    margin: 0;*/
/*}*/
GPUCard {
    display: inline;
}

#root {
    padding: 15px;
}

.ant-card-grid {
    border-radius: 15px;
}

.cover {
    border-radius: 15px;
    /*background:rgba(0,0,0,0.5);*/
    background-color: white;
    /*z-index: 10;*/
}

.my-card {
    float: left;
    width: 33.33%;
    padding: 24px;
    border: 0;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    transition: all 0.3s;
    border-radius: 15px;
}

.my-card:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
}

.dropzone {}

.progress {
    webkit-transition: width .1s ease,background-color .1s ease;
    transition: width .1s ease,background-color .1s ease;
}

.progress::after{
    animation: progress-active 2s ease infinite;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI;;sBAEkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,UAAU;IACV,6BAA6B;AACjC;;AAEA,aAAa;AACb,iBAAiB;AACjB,IAAI;AACJ;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,SAAS;IACT,uHAAuH;IACvH,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,yGAAyG;AAC7G;;AAEA,WAAW;;AAEX;IACI,2DAA2D;IAC3D,oDAAoD;AACxD;;AAEA;IAEI,2CAA2C;AAC/C","sourcesContent":["body {\n    font-family: -apple-system, STKaiti, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n    'Noto Color Emoji';\n}\n\n.author {\n    /*font-weight: 500;*/\n    text-align: center;\n    padding: 0;\n    /*margin: -40px 0px 0px 0px;*/\n}\n\n/*.author>p{*/\n/*    margin: 0;*/\n/*}*/\nGPUCard {\n    display: inline;\n}\n\n#root {\n    padding: 15px;\n}\n\n.ant-card-grid {\n    border-radius: 15px;\n}\n\n.cover {\n    border-radius: 15px;\n    /*background:rgba(0,0,0,0.5);*/\n    background-color: white;\n    /*z-index: 10;*/\n}\n\n.my-card {\n    float: left;\n    width: 33.33%;\n    padding: 24px;\n    border: 0;\n    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;\n    transition: all 0.3s;\n    border-radius: 15px;\n}\n\n.my-card:hover {\n    position: relative;\n    z-index: 1;\n    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);\n}\n\n.dropzone {}\n\n.progress {\n    webkit-transition: width .1s ease,background-color .1s ease;\n    transition: width .1s ease,background-color .1s ease;\n}\n\n.progress::after{\n    -webkit-animation: progress-active 2s ease infinite;\n    animation: progress-active 2s ease infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useCallback, useEffect, useState} from 'react';
import {Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text, ThemeProvider, Image} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';
import {useDropzone} from 'react-dropzone'
import {Progress} from 'semantic-ui-react'
import {v4} from 'uuid' // https://stackoverflow.com/questions/71633646/how-do-i-fix-this-error-uncaught-rangeerror-maximum-call-stack-size-exceeded-a
import {useCookies} from 'react-cookie'

import img_nju from '../imgs/NJU.jpg'
import img_njucs from '../imgs/NJU_CS.png'
import img_medai from '../imgs/NJUMedAI.png'
import img_gulou from '../imgs/gulou.png'
import left_arrow from '../imgs/icons/left_arrow.png'
import right_arrow from '../imgs/icons/right_arrow.png'

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


const format_file_path = (path, length) => {
    if (path.length > length) {
        let tmp = path.split(".");
        let ret = ''
        ret += tmp[0].slice(0, length - 5);
        ret += '--.'
        ret += tmp[tmp.length - 1]
        return ret
    } else {
        return path;
    }
}

const LinePrediction = (props) => {
    const [result, setResult] = useState("");

    const send_data = () => {
        let word = document.getElementById('word').value.trim();
        if (word !== "") {
            fetch(props.root + `/api/ICD/process_word/` + word)
                .then(result => result.json())
                .then(data => {
                    console.log(data);
                    setResult(data['ret']);
                })
                .catch(error => error);
        }
    }

    return <Div align='center' textAlign='center'>
        <Div d="flex" align='center' textAlign='center'>
            <Input placeholder="输入病情描述" h="3rem" textSize="subheader" m={{r: "10px"}} id="word"/>
            <Button
                h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={send_data}
            >分析</Button>
        </Div>
        <Div d="flex" p={{t: "15px"}}>
            <Text p={{r: "20px"}} textWeight={500} textSize="subheader">ICD 映射结果： </Text>
            <Text textColor="info600" textWeight={500} textSize="subheader">{result}</Text>
        </Div>
    </Div>
}

const FilePrediction = (props) => {
    const [sFiles, setSFiles] = useState([]);
    const [useSample, setUseSample] = useState(false);
    const [showModal, setShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [pred, setPred] = useState(-1);
    const [prob, setProb] = useState("");
    const [cam, setCAM] = useState("");
    const [imgIdx, setImgIdx] = useState(0);
    const [training, setTraining] = useState("done");
    const [global_imgPath, setGlobalImagePath] = useState("");
    const [cookies, setCookie] = useCookies(['covid_guid']);
    if (cookies.covid_guid === undefined) {
        setCookie('covid_guid', v4(), {path: '/'});
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.sort((a, b) => parseInt(a.path.split('.')[0]) > parseInt(b.path.split('.')[0]));
        setSFiles(acceptedFiles)
        // disable sample when new files are selected
        setUseSample(false)
        setCAM("")
        setGlobalImagePath("")
        show_image('left') // for updating image view immediately
      }, [])

    const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept} = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg'
    })

    const show_image = (dir) => {
        if(training !== 'done'){
            return;
        }
        if(sFiles.length === 0) {
            setImgIdx(0);
            setCAM("");
            return;
        }
        let incr = dir === 'left' ? -1 : 1;
        let cur_idx = (imgIdx + incr + sFiles.length) % sFiles.length;
        let cur_path = global_imgPath;
        cur_path = cur_path.replace('0.jpg', cur_idx + '.jpg');
        cur_path = cur_path.replace('0.png', cur_idx + '.png');
        setImgIdx(cur_idx);
        setCAM(cur_path);
    }

    const change_file_num = (files) => {
        let start = 0, end = 0;
        if (files.length > 54) {
            start = Math.floor(0.148 * files.length);
            end = Math.floor(0.796 * files.length);
        } else {
            start = Math.floor(0.05 * files.length);
            end = Math.floor(0.83 * files.length);
        }
    }

    const get_largest_consecutive_slices = (files) => {
        if (files.length < 5) {
            return files;
        }
        let prev_start = 0, prev_end = 0, prev_cnt = 0;
        let cur_start = 0, cur_cnt = 0;
        let prev_idx = -100, idx=-1;
        files.forEach((f, i) => {
            idx = parseInt(f.path.split('.')[0]);
            if (idx !== prev_idx + 1) {  // start init
                console.log('start : ', cur_start, 'end : ', i+1, 'cnt : ', cur_cnt)
                if (cur_cnt > prev_cnt) {
                    prev_cnt = cur_cnt;
                    prev_end = i+1;
                    prev_start = cur_start;
                }
                cur_start = i;
                cur_cnt = 1;
                prev_idx = idx;
            } else {
                prev_idx = idx;
                cur_cnt += 1;
            }
        })
        console.log('start : ', cur_start, 'end : ', files.length, 'cnt : ', cur_cnt)
        if (cur_cnt > prev_cnt) {
            prev_cnt = cur_cnt;
            prev_end = files.length;
            prev_start = cur_start;
        }
        console.log('Final start : ', prev_start, 'end : ', prev_end, 'cnt : ', prev_cnt)
        return files.slice(prev_start, prev_end)
    }

    const show_sample_imgs=()=>{
        setSFiles(new Array(16));
        setUseSample(true);
        setImgIdx(0);
        setCAM('sample_imgs$0.png');
        setGlobalImagePath('sample_imgs$0.png');
    }

    const send_file = () => {
        if(acceptedFiles.length == 0){
            show_sample_imgs();
            return;
        }

        let selected_files = get_largest_consecutive_slices(acceptedFiles);
        console.log('selected files : ', selected_files);

        if (selected_files.length < 5) {
            setMsg("请至少上传连续的 5 张图片!")
            setShow(true);
            return
        }
        setUseSample(false);
        // Update the formData object
        setTraining("train");

        // Create an object of formData
        const formData = new FormData();
        formData.append('id', cookies.covid_guid);
        selected_files.forEach((file, i) => {
            formData.append('images' + i, file, file.path);
        })
        setSFiles(selected_files)
        setImgIdx(0)

        fetch(props.root + `/api/Covid/process_data`, {
            method: 'post',
            body: formData,
        })
            .then(result => result.json())
            .then(data => {
                console.log(data);
                setProb(data['prob']);
                setPred(data['pred']);
                setGlobalImagePath(data['img_path']);
                setImgIdx(0);
                setCAM((data['img_path']))
                // setImagePath(data['img_path']);
                setTraining("done");
            })
            .catch(error => error);
    }

    const onClose = () => {
        setShow(false);
    };

    return <Div w={props.img_width * 2 + 10 + "rem"}>

        <Modal isOpen={showModal} onClose={onClose} align="center" rounded="md">
            <Icon name="Cross" pos="absolute" top="1rem" right="1rem" size="16px" onClick={onClose} cursor="pointer"/>
            <Div d="flex" m={{b: "4rem"}}>
                <Icon name="AlertSolid" color="warning700" m={{t: "0.35rem", r: "0.5rem"}}/>
                <Text p={{l: "0.5rem", t: "0.25rem"}} textSize="subheader">
                    {msg}
                </Text>
            </Div>
            <Div d="flex" justify="flex-end">
                <Button onClick={onClose} bg="info700">
                    确认
                </Button>
            </Div>
        </Modal>

        <Div d='flex' justify="center" flexDir="row" p={{l: "2rem", r: "2rem", t: "1rem"}}>
            <Text textSize="subheader" textColor="gray800" m={{r: "1rem"}}>
                {sFiles.length === 0 ? 0 : imgIdx + 1}/{sFiles.length}
            </Text>
        </Div>
        <Div d='flex' justify="center" flexDir="row" p={{l: "2rem", r: "2rem", t: "1rem"}}>
            {/* page prev button */}
            <Div d='flex' justify="center" flexDir="column" h={props.img_width + "rem"}>
                <Image onClick={() => {
                    show_image('left')
                }} cursor={'pointer'}
                       src={left_arrow} w={"2rem"} h={"2rem"} border="0px" borderColor="white" m={{l: "1rem", r: "0.6rem"}}/>
            </Div>
            <Div align="center" textAlign="center" transition d='flex' justify="center" flexDir="column" m={{r: "10px"}}>
                {/* input image */}
                <Div
                    rounded="md" hoverBorderColor="info700"
                    bg="gray200"
                    border="2px dashed" borderColor={isDragActive ? (isDragAccept ? "success600" : "danger600") : "gray400"}
                    w={props.img_width + "rem"} h={props.img_width + "rem"}
                    justify={'center'} d={'flex'} flexDir={'column'}
                    {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} type="file" accept="image/png, image/jpeg"/>
                    {
                        useSample && cam.length > 0 ? <Image src={props.root + "/api/get_img/" + cam.replace('png', 'jpg')}
                                          w={props.img_width + "rem"} h={props.img_width + "rem"}  border="0px" borderColor="white"/>
                        :
                        sFiles.length === 0 ?
                            <Div>
                                <Text textSize="heading" textColor="gray500" textWeight="500">拖动或点击上传</Text>
                                <Text textSize="heading" textColor="gray500" textWeight="500">多张图片 (.jpg / .png)</Text>
                                <Text textSize="heading" textColor="gray500" textWeight="500">图片名之间应连续</Text>
                            </Div>
                            :
                             <Image src={sFiles.length > 0 && imgIdx < sFiles.length ? URL.createObjectURL(sFiles[imgIdx]) : ""}
                                   w={props.img_width + "rem"} h={props.img_width + "rem"} m={{l: "-2px"}}
                            />
                    }
                </Div>
                {/* submit button */}
                <Button
                    m={{t: "1rem"}}
                    h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                    border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block"
                    onClick={send_file}
                    // onClick={show_sample_imgs}
                    // disabled={training !== "done"}
                >{training === "done" ? "模型预测" : (training === "train" ? "分析中..." : "准备中...")}</Button>
            </Div>
            {/* page next button */}
            <Div d='flex' justify="center" flexDir="column" h={props.img_width + "rem"}>
                <Image onClick={() => {
                    show_image('right')
                }} cursor={'pointer'}
                       src={right_arrow} w={"2rem"} h={"2rem"} border="0px" borderColor="white" m={{l: "0rem", r: "0.8rem"}}/>
            </Div>

            <Div align="center" textAlign="center" transition
                 d='flex' justify="center" flexDir="column"
                 m={{l: "10px", r: "2rem"}}>
                <Div
                    rounded="md" hoverBorderColor="info700"
                    bg="gray200"
                    border="2px"
                    w={props.img_width + "rem"} h={props.img_width + "rem"}
                    align={'center'} d="flex" justify={'center'} flexDir={'column'}
                >
                    {
                        cam.length > 0 ?
                            <Image src={props.root + "/api/get_img/" + cam} w={props.img_width + "rem"} h={props.img_width * 0.718 + "rem"} border="0px" borderColor="white"/>
                            : <Text textSize="heading" textColor="gray500" textWeight="500">左边上传图片</Text>
                    }
                </Div>

                <Button
                    m={{t: "1rem"}}
                    h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="black600" bg="white"
                    border="2px solid" borderColor="black600" d="inline-block" cursor="corsshair"
                >{training === "done" ? "激活区域" : (training === "train" ? "分析中..." : "准备中...")}</Button>

            </Div>
        </Div>

        <Div d="flex" justify='center' textAlign='center' w={props.img_width * 2 + 10 + "rem"} m={{t: "1.3rem"}}>
            <Text p={{r: "10px"}} textWeight={500} textSize="subheader" m={{l: "20px"}}>诊断 :</Text>
            <Text p={{r: "10px"}} textWeight={500} textSize="subheader" textColor="info600">{pred == -1 ? "未诊断" : (pred === 0 ? "吸收期" : "进展期")}</Text>

            <Text p={{r: "10px"}} textWeight={500} textSize="subheader" m={{l: "20px"}}>预测概率 :</Text>
            <Text p={{r: "10px"}} textWeight={500} textSize="subheader" textColor="info600">{prob >= 0 ? (prob * 100).toFixed(2) : "  "}</Text>
            <Text p={{r: "10px"}} textWeight={500} textSize="subheader" m={{l: "-4px"}}>%</Text>
        </Div>

    </Div>
}

const Covid = (props) => {
    let wedget_width = 50;
    let img_width = 20;
    let logo_width = 90;
    let [online, setOnline] = useState(0);
    let [visits, setVisits] = useState(0);

    const [cookies, setCookie] = useCookies(['covid_guid']);
    if (cookies.covid_guid === undefined) {
        setCookie('covid_guid', v4(), {path: '/'});
    }

    function fetch_visited_users(){
        fetch(props.root + "/api/Covid/user_visit", {
            method: 'POST',
            body: JSON.stringify({'id': cookies.covid_guid}),
        })
            .then(response => response.json())
            .then(data => {
                setVisits(data['all_visits']);
                setOnline(data['recent_visits']);
            })
            .catch(error => error);
    }

    useEffect(() => {
        fetch_visited_users();
        // let timer = setTimeout(() => {fetch_visited_users()}, 500);
        // return () => {
        //     clearTimeout(timer);
        // }
    }, []);

    return <StyletronProvider value={props.engine}>
        <ThemeProvider>
            <StyleReset/>

            <Div d="flex" flexDir="column" justify="center" align="center" m={{b: "10px"}}>
                <Text
                    textColor="black900"
                    textSize="display2"
                    textWeight="500"
                    p={{t: "1rem", b: "0.5rem"}}
                    border={{b: "1px solid"}}
                    borderColor="gray400">
                    病毒性肺炎智能分期诊断系统
                </Text>

                <Text textSize="subheader" textWeight="600" p={{t: "5px", b: "15px"}} textColor={"gray900"}>测试平台</Text>

                <Text textSize="subheader" m={{b: "1px"}} textWeight={600} textColor="black900">病毒性肺炎起病和进展均较为隐匿，就诊时部分患者胸部CT已出现肺炎表现。快速判断肺炎处于进展期或缓解期有助于了解病情，并可为制定精确的疗法和优化医疗资源分配提供参考。</Text>
                <Text textSize="subheader" m={{b: "1px"}} textWeight={600} textColor="black900">本人工智能程序经过大量病毒性肺炎病例胸部CT影像的学习和训练，可以准确地依据单次肺部CT判断病程处于进展期或吸收期，还可筛选出典型的病灶和显示诊断可信度供临床医生参考。</Text>
                <Text textSize="subheader" m={{b: "1px"}} textWeight={600} textColor="black900">本程序的诊断结果仅供参考，具体临床诊断和治疗请听取医师意见。</Text>
            </Div>

            <Div d="flex" flexDir="column" justify="center" align="center">
                <Div w={wedget_width + "rem"}>
                    <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md">
                        <Text textSize="subheader" m={{b: "5px"}} textWeight={600} textColor="#506070">使用说明：</Text>
                        <Text textSize="subheader" m={{b: "5px"}} textWeight={400} textColor="#506070">1.点击左边方框上传至少五张图片（jpg或png），要求图片文件名对应连续，如1、2、3、4、5;</Text>
                        <Text textSize="subheader" m={{b: "5px"}} textWeight={400} textColor="#506070">2.上传成功后，点击“模型预测”即可运行系统，阅读分期诊断结果、可信度和激活区域;</Text>
                        <Text textSize="subheader" m={{b: "5px"}} textWeight={400} textColor="#506070">3.未上传图片时点击“模型预测”可查看图片示例。</Text>
                        <Div d="flex" flexDir="column" justify="center" align="center">
                            <FilePrediction img_width={img_width} root={props.root}/>
                        </Div>
                    </Div>
                </Div>
                {/* [01-01 change] add a div to display online visitors and history visitors*/}
                <br/>
                <Div d="flex" justify='center' textAlign='center' m={{r: "1rem", t:"10px",}}>
                    <Text textSize="subheader"  textWeight={400} textColor="gray900">当前在线人数： </Text>
                    <Text textSize="subheader" textWeight={400}
                          textColor="info600"> {online}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Text textSize="subheader" textWeight={400} textColor="gray900"> 历史访问人数： </Text>
                    <Text textSize="subheader" textWeight={400}
                          textColor="info600"> {visits} </Text>
                </Div>
                {/* [end of change] */}
                <Div d="flex" flexDir="row" justify="center" align="center" m={{t: "1rem", l: "3rem"}}>
                    <Image src={img_gulou} h={logo_width + "px"} w={logo_width + 25 + "px"} m={{r: "30px"}}/>
                    <Image src={img_nju} h={logo_width + "px"} w={(logo_width / 1.2) + "px"} m={{r: "30px"}}/>
                    <Image src={img_medai} h={logo_width + "px"} w={logo_width + "px"} m={{r: "30px"}}/>
                    <Image src={img_njucs} h={logo_width + "px"} w={(logo_width - 20) + "px"} m={{r: "30px"}}/>
                </Div>
            </Div>

        </ThemeProvider>
    </StyletronProvider>
}

const exported_object = {Covid: Covid}
export default exported_object;
export {Covid}

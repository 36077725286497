import {
  Button, Div, Icon,
  Image,
  Modal, StyleReset, Text,
  ThemeProvider
} from "atomize";
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Provider as StyletronProvider } from "styletron-react";
import '../index.css';

import img_gulou from '../imgs/gulou.png';
import img_nju from '../imgs/NJU.jpg';
import img_njucs from '../imgs/NJU_CS.png';
import img_medai from '../imgs/NJUMedAI.png';

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

const generateRequestId = () => {
  return `req_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
};

const AIPrediction = (props) => {
  const [centroid1, setCentroid1] = useState("");
  const [centroid2, setCentroid2] = useState("");
  const [rotationAngle, setRotationAngle] = useState("");
  const [combinedFileName, setCombinedFileName] = useState("");
  const [showModal, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [progress, setProgress] = useState(-1);
  const [requestId, setRequestId] = useState(null);
  const [files, setFiles] = useState([]);
  const [isDir, setIsDir] = useState(false);
  const [dirName, setDirName] = useState("");
  const [disabled, setDisabled] = useState(true);

  // 分片上传函数
  const uploadFileChunk = async (file, chunkSize) => {
    let offset = 0;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const filename = file.name;
    const request_all = [];
    for (let i = 0; i < totalChunks; i++) {
      const chunk = file.slice(offset, offset + chunkSize);
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('number', i + 1);
      formData.append('total', totalChunks);
      formData.append('filename', filename);
      // 使用fetch API发送分片
      try {
        const _ = fetch(`${props.root}/api/NII/upload`, {
          method: 'POST',
          body: formData
        })
        request_all.push(_);
      } catch (error) {
        setMsg(`文件上传分片${i + 1}失败, 请重新上传文件`);
        setShow(true);
        return;
      } finally {
        // 更新偏移量
        offset += chunkSize;
      }
    }
    await Promise.all(request_all);
    setDisabled(false);
    setFiles([file]);
    setMsg("文件上传完成");
    setShow(true);
  };

  const handleFilesUploadOld = async (files) => {
    if (!files || files.length === 0) {
      return;
    }
    setDisabled(true);
    // 定义分片大小，例如：5MB
    const chunkSize = 10 * 1024 * 1024;
    // 定义后端API的URL
    try {
      await uploadFileChunk(files[0], chunkSize);
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleFilesUploadNew = async (files) => {
    if (!files || files.length === 0) return;

    // 使用request ID对应一次请求，而不是文件名
    const requestId = generateRequestId();
    // 定义分片大小，Current: 2MB
    const chunkSize = 5 * 1024 * 1024;
    const MAX_CONCURRENT = 10;
    const controller = new AbortController();

    setRequestId(requestId);
    setDisabled(true);

    try {
      // 动态计算总切片数（减少内存占用）
      const totalChunks = files.reduce((sum, file) =>
        sum + Math.ceil(file.size / chunkSize), 0);

      function* generateChunks() {
        for (const [fileIndex, file] of files.entries()) {
          const totalFileChunks = Math.ceil(file.size / chunkSize);
          for (let chunkIndex = 0; chunkIndex < totalFileChunks; chunkIndex++) {
            const offset = chunkIndex * chunkSize;
            yield {
              chunk: file.slice(offset, offset + chunkSize),
              chunkIndex,
              totalFileChunks,
              file,
              fileIndex,
            };
          }
        }
      }

      const chunkGenerator = generateChunks();
      let uploadedCount = 0;
      while (true) {
        const batch = [];
        while (batch.length < MAX_CONCURRENT) {
          const { value: chunkInfo, done } = chunkGenerator.next();
          if (done) {
            break;
          }
          batch.push(chunkInfo);
        }

        if (batch.length === 0) {
          break;
        }


        await Promise.all(
          batch.map(async ({ chunk, chunkIndex, totalFileChunks, file, fileIndex }) => {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('filename', file.name);
            formData.append('chunk_id', chunkIndex);
            formData.append('chunk_total', totalFileChunks);
            formData.append('file_id', fileIndex);
            formData.append('file_total', files.length);
            formData.append('request_id', requestId);

            const response = await fetch(`${props.root}/api/NII/upload`, {
              method: 'POST',
              body: formData,
              signal: controller.signal,
            });

            if (!response.ok) {
              throw new Error(
                `文件 ${file.name} 分片 ${chunkIndex + 1}/${totalFileChunks} 上传失败`
              );
            }

            // 原子性更新进度
            uploadedCount++;
            setProgress(Math.round((uploadedCount / totalChunks) * 100));
          })
        );
      }

      setFiles(files);
      setMsg("文件上传完成");
    } catch (error) {
      console.error('File upload failed:', error);
      controller.abort();
      setMsg(error.message.includes("分片") ? error.message : "文件上传失败，请重新上传");
      setProgress(-1);
    } finally {
      setDisabled(false);
      setShow(true);
    }
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    let errmsg = "";
    let isDirUpload = false;
    let validFiles = [];

    if (acceptedFiles.length === 1) {
      // 检查单文件是.nii
      const file = acceptedFiles[0];
      if (!file.name.endsWith(".nii") && !file.name.endsWith(".nii.gz")) {
        errmsg = "单个文件必须是.nii文件";
      } else {
        validFiles.push(file);
      }
    }
    else {
      // 检查多文件(文件夹)是否全部是.dcm
      for (const file of acceptedFiles) {
        if (file.name.endsWith(".dcm")) {
          validFiles.push(file);
        }
      }
      if (validFiles.length === 0) {
        errmsg = "文件夹中的文件必须是.dcm文件";
      } else {
        // 不允许.dcm出现在不同的文件层级
        const relativePaths = validFiles.map(f => f.webkitRelativePath);
        const parentDirs = relativePaths.map(path => path.split("/").slice(0, -1).join("/"));
        const baseDir = parentDirs[0];
        const isSameLevel = parentDirs.every(dir => dir === baseDir);
        console.log(validFiles[0].webkitRelativePath, baseDir, isSameLevel);
        setDirName(baseDir);

        if (!isSameLevel) {
          errmsg = "上传文件夹不得包含嵌套";
        } else {
          isDirUpload = true;
        }
      }
    }

    if (errmsg !== "") {
      setMsg(errmsg);
      setShow(true);
      return;
    }

    if (validFiles.length > 0) {
      setFiles(validFiles);
      setIsDir(isDirUpload);
      setProgress(0);
      handleFilesUploadNew(validFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/gzip": [".nii", ".nii.gz", ".dcm"],
    },
    multiple: true,
  });

  const sendData = () => {
    if (!files || requestId === null) {
      setMsg("请选择一个文件");
      setShow(true);
      return;
    }
    const formData = new FormData();
    formData.append('request_id', requestId);
    fetch(`${props.root}/api/NII/infer`, {
      method: 'POST',
      body: formData
    }).then(result => result.json()).then(data => {
      setCentroid1(data['centroid1']);
      setCentroid2(data['centroid2']);
      setRotationAngle(data['rotation_angle']);
      setCombinedFileName(data['combined_file_name']);
    }).catch(error => {
      console.error(error);
      setMsg("预测失败");
      setShow(true);
    });
  };

  const downloadFile = () => {
    if (!combinedFileName) {
      console.error('No file name available for download');
      setMsg("文件名不可用");
      setShow(true);
      return;
    }
    fetch(`${props.root}/api/NII/download_file?request_id=${requestId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', combinedFileName || "download.nii");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error('Error downloading the file', error);
        setMsg("下载失败，请重试");
        setShow(true);
      });
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <Div align='center' textAlign='center'>
      <Text textSize="title" m={{ b: "15px" }} textWeight={300}></Text>
      <Div d="flex" align='center' textAlign='center'>
        <button>
          <Div {...getRootProps()} border="2px dashed #ddd" p="1rem" cursor="pointer">
            <input {...getInputProps()} />
            {files.length > 0 ?
              (
                isDir ? (
                  <Text>文件夹 {dirName} ({files.length}个文件)</Text>
                ) : (
                  <Text>{files[0].name}</Text>
                )
              ) : (
                <Text>拖拽或点击上传 .nii 单文件或 .dcm 文件夹</Text>
              )}
            {progress >= 0 && (
              <Div mt="1rem">
                <Div
                  bg="#eee"
                  height="8px"
                  borderRadius="4px"
                  overflow="hidden"
                >
                  <Div
                    bg="#6c5ce7"
                    width={`${progress}%`}
                    height="100%"
                    transition="width 0.3s ease"
                  />
                </Div>
                <Text textColor="#6c5ce7" mt="0.5rem">
                  {Math.round(progress)}% 已上传
                </Text>
              </Div>
            )}
          </Div>
        </button>
        <Button
          h="3rem" p={{ x: "1rem" }} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
          border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={sendData}
          m={{ l: "20px" }} disabled={disabled}
        >预测</Button>
        <Button
          h="3rem" p={{ x: "1rem" }} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
          border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={downloadFile}
          m={{ l: "20px" }} disabled={disabled}
        >下载文件</Button>
      </Div>

      <Div d="flex" p={{ t: "15px" }}>
        <Text p={{ r: "20px", l: "5px" }} textWeight={500} textSize="subheader">预测结果： </Text>
      </Div>
      <Div d="flex" flexDir="column" align="start" p={{ t: "15px" }}>
        <Text textColor="#000000" textWeight={500} textAlign={'left'} textSize="subheader">Centroid 1: {centroid1}</Text>
        <Text textColor="#000000" textWeight={500} textAlign={'left'} textSize="subheader">Centroid 2: {centroid2}</Text>
        <Text textColor="#000000" textWeight={500} textAlign={'left'} textSize="subheader">Rotation Angle: {rotationAngle}</Text>
      </Div>

      <Modal isOpen={showModal} onClose={onClose} align="center" rounded="md">
        <Icon name="Cross" pos="absolute" top="1rem" right="1rem" size="16px" onClick={onClose} cursor="pointer" />
        <Div d="flex" m={{ b: "1rem" }}>
          <Icon name="AlertSolid" color="warning700" m={{ t: "0.15rem", r: "0.5rem" }} />
          <Text p={{ l: "0.5rem", t: "0.25rem" }} textSize="subheader">
            {msg}
          </Text>
        </Div>
        <Div d="flex" justify="flex-end">
          <Button onClick={onClose} bg="info700">
            确认
          </Button>
        </Div>
      </Modal>
    </Div>
  );
}

const NII = (props) => {
  let width = 32;
  let logo_width = 90;
  return (
    <StyletronProvider value={props.engine}>
      <ThemeProvider>
        <StyleReset />
        <Div d="flex" flexDir="column" justify="center" align="center" m={{ b: "10px" }}>
          <Text
            textColor="black900"
            textSize="display2"
            textWeight="500"
            p={{ t: "1rem", b: "0.5rem" }}
            border={{ b: "1px solid" }}
            borderColor="gray400">
            AI计算模型
          </Text>
          <Text textSize="subheader" textWeight="600" p={{ t: "5px", b: "15px" }} textColor={"gray900"}>测试平台</Text>
        </Div>
        <Div d="flex" flexDir="column" justify="center" align="center">
          <Div w="45rem">
            <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md" m={{ b: "30px" }}>
              <Text textSize="title" m={{ b: "15px", l: "10px" }} textWeight={500}>临床数据：</Text>
              <Div d="flex" flexDir="column" m={{ l: "10px" }} justify="center" align="center">
                <AIPrediction width={width} root={props.root} />
              </Div>
            </Div>
          </Div>
          <Div d="flex" flexDir="row" justify="center" align="center" m={{ t: "2rem", l: "1rem" }}>
            <Image src={img_gulou} h={logo_width + "px"} w={(logo_width / 0.9) + "px"} m={{ r: "30px" }} />
            <Image src={img_nju} h={logo_width + "px"} w={(logo_width / 1.2) + "px"} m={{ r: "30px" }} />
            <Image src={img_njucs} h={logo_width + "px"} w={(logo_width - 20) + "px"} m={{ r: "30px" }} />
            <Image src={img_medai} h={logo_width + "px"} w={logo_width + "px"} />
          </Div>
        </Div>
      </ThemeProvider>
    </StyletronProvider>
  );
}

const exported_object = { NII };
export default exported_object;
export { NII };


import React, {useState} from 'react';
import {Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text, ThemeProvider, Image} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';
import {useDropzone} from 'react-dropzone'
import {Progress} from 'semantic-ui-react'

import img_GuoXin from '../imgs/GuoXin.png'
import img_nju from '../imgs/NJU.jpg'
import img_njucs from '../imgs/NJU_CS.png'
import img_medai from '../imgs/NJUMedAI.png'

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


const format_file_path = (path, length) => {
    if (path.length > length) {
        let tmp = path.split(".");
        let ret = ''
        ret += tmp[0].slice(0, length - 5);
        ret += '--.'
        ret += tmp[tmp.length - 1]
        return ret
    } else {
        return path;
    }
}

const LinePrediction = (props) => {
    const [result, setResult] = useState("");

    const send_data = () => {
        let word = document.getElementById('word').value.trim();
        if (word !== "") {
            fetch(props.root + `/api/ICD/process_word/` + word)
                .then(result => result.json())
                .then(data => {
                    console.log(data);
                    setResult(data['ret']);
                })
                .catch(error => error);
        }
    }

    return <Div align='center' textAlign='center'>
        <Div d="flex" align='center' textAlign='center'>
            <Input placeholder="输入病情描述" h="3rem" textSize="subheader" m={{r: "10px"}} id="word"/>
            <Button
                h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={send_data}
            >分析</Button>
        </Div>
        <Div d="flex" p={{t: "15px"}}>
            <Text p={{r: "20px"}} textWeight={500} textSize="subheader">ICD 映射结果： </Text>
            <Text textColor="info600" textWeight={500} textSize="subheader">{result}</Text>
        </Div>
    </Div>
}

const FilePrediction = (props) => {
    const [filename, setFilename] = useState("");
    const [training, setTraining] = useState("done");
    const [showModal, setShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [percent, setPercent] = useState(0);
    const [code, setCode] = useState("2fd40f2b-47f8-49e6-81c9-5800d009b2b6,folder.csv");
    const [task_id, setTaskId] = useState("");

    const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept} = useDropzone()
    // useDropzone({accept: "text/csv"})

    const load_task = () => {
        let code = document.getElementById('code').value.trim();
        if (code.length > 37) {
            let [task_id, filename] = code.split(',')
            retrieve_progress(task_id, filename);
        } else {
            setMsg("请使用返回的完整任务编码，例如：b68ede85-0ea2-425e-8fd9-5e0a97d1cdf8,result-157.csv")
            setShow(true);
        }
    }

    const retrieve_progress = (task_id, filename) => {
        let url = props.root + '/api/progress/' + task_id
        fetch(url).then(result => result.json()).then(data => {
            console.log(data);
            let state = data['state']
            if (state === 'PROGRESS') {
                setPercent(data.details.percent);
                setTimeout(retrieve_progress, 1000, task_id, filename);
                setTraining("train");
            } else if (state === 'PENDING') {
                setTimeout(retrieve_progress, 1000, task_id, filename);
                setTraining("prepare");
            } else if (state === "REVOKED") {
                setTraining("done");
            } else if (state === 'SUCCESS') {
                filename = data.details
                setPercent(100);
                setFilename(props.root + "/api/download/" + filename);
                setTraining("done");
            }
        }).catch(error => error);
    }

    const send_file = () => {
        if (acceptedFiles.length === 1) {
            let file = acceptedFiles[0];
            if (!(file.path.endsWith(".csv") || file.path.endsWith(".txt"))) {
                setMsg("只支持 .csv 或 .txt 文件");
                setShow(true);
                return
            }

            // Create an object of formData
            const formData = new FormData();

            // Update the formData object
            formData.append("files", file, file.path);
            setTraining("prepare");
            setPercent(0);

            fetch(props.root + `/api/ICD/process_file`, {
                method: 'post',
                body: formData,
            })
                .then(result => result.json())
                .then(data => {
                    console.log(data);
                    if (data['filename'].length > 0) {
                        document.getElementById("code").value = data['task_id'] + ',' + data['filename'];
                        setTaskId(data['task_id']);
                        retrieve_progress(data['task_id'], data['filename']);
                    }
                })
                .catch(error => error);
        } else if (acceptedFiles.length > 1) {
            setMsg("当前只能支持单个文件");
            setShow(true);
        }
    }

    const revoke_task = () => {
        fetch(props.root + `/api/revoke/` + task_id).then(result => result.json()).then(data => {
            console.log(data);
            if (data['revoke'] === 'success') {
                setTaskId("");
                document.getElementById("code").value = "";
                setTraining("done");
                setFilename("");
                setPercent(0);
            }
        }).catch(error => error);
    }

    const onClose = () => {
        setShow(false);
    };

    return <Div w={props.width + "rem"}>

        <Modal isOpen={showModal} onClose={onClose} align="center" rounded="md">
            <Icon name="Cross" pos="absolute" top="1rem" right="1rem" size="16px" onClick={onClose} cursor="pointer"/>
            <Div d="flex" m={{b: "4rem"}}>
                <Icon name="AlertSolid" color="warning700" m={{t: "0.35rem", r: "0.5rem"}}/>
                <Text p={{l: "0.5rem", t: "0.25rem"}} textSize="subheader">
                    {msg}
                </Text>
            </Div>
            <Div d="flex" justify="flex-end">
                <Button onClick={onClose} bg="info700">
                    确认
                </Button>
            </Div>
        </Modal>

        <Div border="2px dashed" p={{x: "1rem", y: "0.5rem"}} w={props.width + "rem"} h="7rem"
             borderColor={isDragActive ? (isDragAccept ? "success600" : "danger600") : "gray400"}
             align="center" textAlign="center" transition bg="gray200"
             d='flex' justify="center" flexDir="column" rounded="md" hoverBorderColor="info700"
             {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} type="file" accept="text/csv"/>
            {
                acceptedFiles.length === 0 ?
                    <Text textSize="subheader" textColor="gray500" textWeight="500">拖动或点击上传 (.txt/.csv)</Text> :
                    acceptedFiles.map(file => (
                        <Text textSize="subheader" textColor="info700" textWeight="500" key={file.path}
                        >{format_file_path(file.path, 30)}</Text>
                    ))
            }
        </Div>

        <Div d="flex" justify="space-between" m={{t: "15px"}}>
            <Div w={(props.width - 5) + "rem"} style={{clear: "none"}} m={{b: "-15px"}}>
                <Progress percent={percent} indicating={percent > 0}/>
            </Div>
            <Text textAlign="center" align="center" textSize="1.2rem" m={{t: "3px"}} d="inline-block"
                  textWeight="700" textColor="gray800">{percent.toFixed(1)}%</Text>
        </Div>


        <Div d="flex" justify="space-between" m={{t: "15px", b: "20px"}} textAlign="center" align="center">

            {/*<Text textAlign="center" align="center" textSize="1rem" d="inline-block" textWeight="700" textColor="gray800">任务编码：</Text>*/}
            <Input h="2rem" textsize="0.8rem" id="code" w="26rem" placeholder="任务编码：全选复制后，无需在线等待任务完成，可随时加载"/>

            <Button textsize="1rem" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200" h="2rem"
                    border="1px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" p={{x: "0.75rem"}}
                    onClick={load_task}>加载任务</Button>
        </Div>

        <Div d='flex' flexWrap='row' justify="space-around" w={props.width + "rem"} m={{t: "5px"}}>
            <Button
                h="3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block"
                onClick={send_file} disabled={training !== "done"}
            >{training === "done" ? "开始分析" : (training === "train" ? "分析中..." : "准备中...")}</Button>

            <Button
                h="3rem" textSize="subheader" hoverTextColor="info700" textColor="info600"
                bg="white" hoverBg="info200" border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block"
                onClick={revoke_task} disabled={training === "done"}
            > 停止分析
            </Button>

            <Button
                h="3rem" textSize="subheader" hoverTextColor="info700" textColor="info600"
                bg="white" hoverBg="info200" border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block"
                disabled={filename.length === 0}
            >
                {filename.length > 0 ? <Anchor textColor="info600" href={filename} onClick={() => {
                    setFilename("")
                }}> 下载结果 </Anchor> : <div>下载结果</div>}
            </Button>
        </Div>
    </Div>
}

const ICD = (props) => {
    let width = 32;
    let logo_width = 90;

    return <StyletronProvider value={props.engine}>
        <ThemeProvider>
            <StyleReset/>

            <Div d="flex" flexDir="column" justify="center" align="center" m={{b: "10px"}}>
                <Text
                    textColor="black900"
                    textSize="display2"
                    textWeight="500"
                    p={{t: "1rem", b: "0.5rem"}}
                    border={{b: "1px solid"}}
                    borderColor="gray400">
                    ICD 智能分析系统
                </Text>

                <Text textSize="subheader" textWeight="600" p={{t: "5px", b: "15px"}} textColor={"gray900"}>测试平台</Text>
            </Div>

            <Div d="flex" flexDir="column" justify="center" align="center">
                <Div w="40rem">
                    <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md" m={{b: "30px"}}>
                        <Text textSize="title" m={{b: "15px"}} textWeight={500}>单词分析：</Text>
                        <Div d="flex" flexDir="column" justify="center" align="center">
                            <LinePrediction width={width} root={props.root}/>
                        </Div>
                    </Div>

                    <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md">
                        <Text textSize="title" m={{b: "15px"}} textWeight={500}>文件分析：</Text>
                        <Div d="flex" flexDir="column" justify="center" align="center">
                            <FilePrediction width={width} root={props.root}/>
                        </Div>
                    </Div>
                </Div>
                    <Div d="flex" flexDir="row" justify="center" align="center" m={{t: "2rem", l: "3rem"}}>
                        <Image src={img_GuoXin} h={logo_width + "px"} w={(logo_width + 10) + "px"} m={{r: "30px"}}/>
                        <Image src={img_nju} h={logo_width + "px"} w={(logo_width / 1.2) + "px"} m={{r: "30px"}}/>
                        <Image src={img_medai} h={logo_width + "px"} w={logo_width + "px"}  m={{r: "30px"}}/>
                        <Image src={img_njucs} h={logo_width + "px"} w={(logo_width - 20) + "px"} m={{r: "30px"}}/>
                    </Div>
            </Div>

        </ThemeProvider>
    </StyletronProvider>
}

const exported_object = {ICD}
export default exported_object;
export {ICD}
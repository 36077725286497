import React, {useState} from 'react';
import {
    Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text,
    ThemeProvider, Image, Dropdown, currentDevice
} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';

import img_nju from '../imgs/NJU.jpg'
import img_njucs from '../imgs/NJU_CS.png'
import img_medai from '../imgs/NJUMedAI.png'
import img_dz from '../imgs/DZ.jpg'

import cns from '../imgs/organs/CNS.jpg'
import circulation from '../imgs/organs/Circulation.jpg'
import coagulation from '../imgs/organs/coagulation.jpg'
import liver from '../imgs/organs/liver.jpg'
import renal from '../imgs/organs/renal.jpg'
import respiratory from '../imgs/organs/respiratory.jpg'


const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


const INPUTS = [
    {
        'name': 'Respiratory system',
        'info': 'PaO2/FiO2*, mmHg',
        'options': [
            "≥400",
            "300-399",
            "200-299",
            "≤199 and NOT mechanically ventilated",
            "100-199 and mechanically ventilated",
            "<100 and mechanically ventilated"
        ],
        'points': [0, 1, 2, 2, 3, 4],
        'img' : respiratory
    }, {
        'name': 'Coagulation system',
        'info': 'Platelets, ×10^3/µL',
        'options': ['≥150', '100-149', '50-99', '20-49', '<20'],
        'points': [0, 1, 2, 3, 4],
        'img' : coagulation,
    }, {
        'name': 'CNS',
        'info': 'Glasgow Coma Scale',
        'options': ['15', '13–14', '10–12', '6–9', '<6'],
        'points': [0, 1, 2, 3, 4],
        'img' : cns
    }, {
        'name': 'Liver',
        'info': 'Bilirubin, mg/dL (μmol/L)',
        'options': ['<1.2 (<20)', '1.2–1.9 (20-32)', '2.0–5.9 (33-101)', '6.0–11.9 (102-204)', '≥12.0 (>204)'],
        'points': [0, 1, 2, 3, 4],
        'img': liver
    }, {
        'name': 'Circulatory System',
        'info': 'Mean arterial pressure OR administration of vasoactive agents, (mcg/kg/min)',
        'options': [
            'No hypotension',
            'MAP <70 mmHg',
            'DOPamine ≤5 or DOBUTamine (any dose)',
            'DOPamine >5, EPINEPHrine ≤0.1, or norEPINEPHrine ≤0.1',
            'DOPamine >15, EPINEPHrine >0.1, or norEPINEPHrine >0.1'
        ],
        'points': [0, 1, 2, 3, 4],
        'img': circulation
    }, {
        'name': 'Renal',
        'info': 'Creatinine, mg/dL (μmol/L) (or urine output)',
        'options': ['<1.2 (<110)', '1.2–1.9 (110-170)', '2.0–3.4 (171-299)', '3.5–4.9 (300-440) or UOP <500 mL/day)', '≥5.0 (>440) or UOP <200 mL/day'],
        'points': [0, 1, 2, 3, 4],
        'img' : renal
    }
]

const Times = [12, 24, 36, 48, 60, 72];

let global_data = null;
let global_points = null;

function init_data() {
    global_data = new Array(INPUTS.length);
    for (let i = 0; i < global_data.length; i++) {
        global_data[i] = new Array(Times.length).fill(0);
    }
    global_points = new Array(INPUTS.length);
    for (let i = 0; i < global_points.length; i++) {
        global_points[i] = INPUTS[i]['points'];
    }
}

init_data();

const Selection = (props) => {
    const subject_idx = props.subject_idx;
    let info = INPUTS[subject_idx];
    let options = info['options'];
    let points = info['points'];
    let width = props.width; // #"30rem";

    const [showDropdown, setShowDropdown] = useState(new Array(Times.length).fill(false));
    const [selection, setSelection] = useState(new Array(Times.length).fill(0));

    const toggle_dropdown = (idx) => {
        const new_dp = showDropdown.slice();
        new_dp[idx] = !new_dp[idx];
        setShowDropdown(new_dp);
    }

    const change_selection = (time_idx, new_selection) => {
        const new_selection_state = selection.slice();
        new_selection_state[time_idx] = new_selection;
        setSelection(new_selection_state);

        global_data[subject_idx][time_idx] = global_points[subject_idx][new_selection];
        console.log(global_data);
    }

    const get_menu_list = (time_idx) => {
        return <Div>
            {options.map((opt, idx) => (
                <Div d='flex' flexWrap='row' justify="space-between" p={{l: "1rem", r: "1rem", t: "0.5rem", b: "0.5rem"}} rounded="md" cursor="pointer"
                     bg={"white"} hoverBg="gray400" textWeight={"500"} key={idx} textAlign={'left'} onClick={() => {
                    change_selection(time_idx, idx);
                    toggle_dropdown(time_idx)
                }}>
                    <Text style={{'line-height': "17px"}}>{opt}</Text> <Text style={{'line-height': "17px"}}>+{points[idx]}</Text>
                </Div>
            ))}
        </Div>
    }

    return <Div m={{b: "2rem"}} textAlign={'center'} align={'center'} d={'flex'} flexDir="column" justify="center">
        <Div d="flex" flexDir="row"  w={width}>
            <Image src={info['img']} h={"5rem"} w={"5rem"}/>
            <Div w={(width*0.6)+"rem"} m={{r:"1.1rem"}} d="flex" flexDir="column"  justify="center" align="center">
                <Text textSize={'title'} textWeight={"600"} m={{b: "3px"}} >{info['name']}</Text>
                <Text textSize={'paragraph'} textWeight={"600"} textColor="gray800" w="25rem" style={{'line-height': "20px"}}>{info['info']}</Text>
            </Div>
        </Div>
        {
            Times.map((t, idx) => (
                <Div d="flex" flexDir="row" key={idx}>
                    <Div textAlign={'right'} align={'center'} d={'flex'} flexDir="column" justify="center" p={{b: "3px"}}>
                        <Text textSize={'paragraph'} textWeight={"600"} textAlign={'right'} w="3rem" m={{r: "15px"}}>{t}H :</Text>
                    </Div>
                    <Dropdown w={(Math.ceil(width * 0.6)) + "rem"} isOpen={showDropdown[idx]} m={{b: "5px"}} onClick={() => toggle_dropdown(idx)} menu={get_menu_list(idx)}>
                        <Text style={{'line-height': "17px"}} textAlign={'left'}>{options[selection[idx]]}</Text>
                    </Dropdown>
                </Div>
            ))
        }
    </Div>
}

const SelectionTable = (props) => {
    let width = props.width;
    const [result, setResult] = useState(-1);
    const [showModal, setShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [imgPath, setImagePath] = useState("");

    const collect_data = (results) => {
        let age = parseInt(document.getElementById('age').value);

        if (isNaN(age)) {
            setMsg("Please input the admission age!")
            setShow(true);
            return false;
        }

        for (const globalArray of global_data) {
            for (const ele of globalArray) {
                results.push(ele);
            }
        }
        results.push(age);
        return true
    }

    const send_data = () => {
        let result = []
        if (collect_data(result)) {
            fetch(props.root + `/api/ICU/process_data`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    'data': result
                })
            })
                .then(result => result.json())
                .then(data => {
                    console.log(data);
                    setResult(data['result']);
                    setImagePath(data['img_path']);
                })
                .catch(error => error);
        }
    }

    const onClose = () => {
        setShow(false);
    };

    return <Div>
        {
            INPUTS.map((inp, idx) => (<Selection subject_idx={idx} width={width}/>))
        }
        <Div d="flex" flexDir="column" justify="center" align="center" m={{t: "20px", b: "20px"}}>
            <Div d="flex" align='center' textAlign='center' m={{b: "10px"}}>
                <Text p={{r: "10px"}} textWeight={500} textSize="subheader" m={{l: "20px"}}> Admission Age : </Text>
                <Input h="2.2rem" textSize="subheader" m={{r: "20px"}} id="age" w="4rem" textAlign='center' type="number"/>
                <Button
                    w="5.5rem" h="2.3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                    border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={send_data} textAlign={'center'}
                >Predict</Button>

            </Div>

            <Div d="flex" align='center' textAlign='center'>
                <Text p={{r: "10px"}} textWeight={500} textSize="subheader" m={{l: "20px"}}>Probability of in-ICU Mortality (%) :</Text>
                <Text p={{r: "10px"}} textWeight={500} textSize="subheader" textColor="info600">{result >= 0 ? (result * 100).toFixed(2) : "  "}</Text>
            </Div>

            {
                imgPath.length > 0 ?
                    <Div d="flex" flexDir="column" justify="center" align="center" w={(Math.ceil(width * 0.7)) + "rem"} textAlign={'center'}>
                        <Image src={props.root + "/api/get_img/" + imgPath} w={(Math.ceil(width * 0.9)) + "rem"} m={{t: "20px"}}/>
                        <Text textWeight={500} textSize="paragraph" m={{t: "-25px"}} style={{'line-height': "18px"}}> Individualized mortality risk factor analysis by SHAP algorithm </Text>
                    </Div> : ""
            }
        </Div>

        <Modal isOpen={showModal} onClose={onClose} align="center" rounded="md">
            <Icon name="Cross" pos="absolute" top="1rem" right="1rem" size="16px" onClick={onClose} cursor="pointer"/>
            <Div d="flex" m={{b: "4rem"}}>
                <Icon name="AlertSolid" color="warning700" m={{t: "0.35rem", r: "0.5rem"}}/>
                <Text p={{l: "0.5rem", t: "0.25rem"}} textSize="subheader">
                    {msg}
                </Text>
            </Div>
            <Div d="flex" justify="flex-end">
                <Button onClick={onClose} bg="info700">
                    Confirm
                </Button>
            </Div>
        </Modal>

    </Div>
}


const ICU = (props) => {
    let logo_width = 90;
    // console.log(document.width);
    let width = Math.ceil((document.body.scrollWidth * 0.063) * 0.8);
    // xs, sm, md, lg or xl
    switch (currentDevice()) {
        case "xs" :
            width = 30;
            break;
        case "sm" :
            width = 32;
            break;
        case "md" :
            width = 38;
            break;
        case "lg" :
            width = 42;
            break;
        case "xl" :
            width = 45;
            break;
        default :
            width = 45;
    }
    width = Math.ceil((document.body.scrollWidth * 0.063) * 0.8);
    width = width > 45 ? 45 : width;
    width = width < 28 ? 28 : width;

    return <StyletronProvider value={props.engine}>
        <StyleReset/>
        <ThemeProvider>

            <Div d="flex" flexDir="column" justify="center" align="center" m={{b: "10px"}} textAlign={'center'}>
                <Text
                    textColor="black900"
                    textSize="display2"
                    textWeight="500"
                    p={{t: "1rem", b: "0.5rem"}}
                    border={{b: "1px solid"}}
                    borderColor="gray400">
                    T-SOFA System
                </Text>

                <Text textSize="subheader" textWeight="600" p={{t: "5px", b: "15px"}} textColor={"gray900"} w={(width - 7) + "rem"} style={{'line-height': "22px"}}>
                    A Time-Incorporated SOFA-Based Explainable Machine Learning Model for Mortality Prediction in Critically Ill Patients
                </Text>
            </Div>

            <Div d="flex" flexDir="column" justify="center" align="center" m={{b: "3rem"}}>
                <Div w={width + "rem"}>
                    <Div border="1px solid" borderColor="gray300" shadow="2" p="2rem" rounded="md" m={{b: "1rem"}}>
                        {/*<Text textSize="title" m={{b: "5px"}} textWeight={500}>病例分析：</Text>*/}
                        {/*<InputTable maxW={width + "rem"} root={props.root} m={{l: "-4rem"}}/>*/}
                        <SelectionTable root={props.root} width={width}/>
                    </Div>
                    <Div d="flex" flexDir="row" justify="center" align="center" p={{t: "1rem", l: "1rem"}}>
                        <Image src={img_dz} h={logo_width + "px"} w={logo_width + "px"}  m={{r: "30px"}}/>
                        <Image src={img_nju} h={logo_width + "px"} w={(logo_width / 1.2) + "px"} m={{r: "30px"}}/>
                        <Image src={img_medai} h={logo_width + "px"} w={logo_width + "px"} m={{r: "30px"}}/>
                        <Image src={img_njucs} h={logo_width + "px"} w={(logo_width - 20) + "px"} m={{r: "30px"}}/>

                    </Div>
                </Div>
            </Div>


        </ThemeProvider>
    </StyletronProvider>
}

const exported_object = {ICU}
export default exported_object;
export {ICU}
import React, {useState} from 'react';
import {
    Anchor, Button, Div, Icon, Input, Modal, StyleReset, Text,
    ThemeProvider, Image, Dropdown, currentDevice
} from "atomize";
import {Provider as StyletronProvider} from "styletron-react";
import '../index.css';


const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


const Test = (props) => {
    let logo_width = 90;
    // console.log(document.width);
    let width = Math.ceil((document.body.scrollWidth * 0.063) * 0.8);
    // xs, sm, md, lg or xl
    switch (currentDevice()) {
        case "xs" :
            width = 30;
            break;
        case "sm" :
            width = 32;
            break;
        case "md" :
            width = 38;
            break;
        case "lg" :
            width = 42;
            break;
        case "xl" :
            width = 45;
            break;
        default :
            width = 45;
    }
    width = Math.ceil((document.body.scrollWidth * 0.063) * 0.8);
    width = width > 45 ? 45 : width;
    width = width < 28 ? 28 : width;

    const send_data = () => {
        let data = [
            0, // heartrate_1h
            0, // heartrate_2h
            0, // heartrate_3h
            0, // heartrate_4h
            0, // heartrate_5h
            0, // heartrate_6h
            0, // heartrate_7h
            0, // heartrate_8h
            0, // heartrate_9h
            0, // heartrate_10h
            0, // heartrate_11h
            0, // heartrate_12h
            0, // heartrate_13h
            0, // heartrate_14h
            0, // heartrate_15h
            0, // heartrate_16h
            0, // heartrate_17h
            0, // heartrate_18h
            0, // heartrate_19h
            0, // heartrate_20h
            0, // heartrate_21h
            0, // heartrate_22h
            0, // heartrate_23h
            0, // heartrate_24h
            0, // resp_rate_1h
            0, // resp_rate_2h
            0, // resp_rate_3h
            0, // resp_rate_4h
            0, // resp_rate_5h
            0, // resp_rate_6h
            0, // resp_rate_7h
            0, // resp_rate_8h
            0, // resp_rate_9h
            0, // resp_rate_10h
            0, // resp_rate_11h
            0, // resp_rate_12h
            0, // resp_rate_13h
            0, // resp_rate_14h
            0, // resp_rate_15h
            0, // resp_rate_16h
            0, // resp_rate_17h
            0, // resp_rate_18h
            0, // resp_rate_19h
            0, // resp_rate_20h
            0, // resp_rate_21h
            0, // resp_rate_22h
            0, // resp_rate_23h
            0, // resp_rate_24h
            0, // sbp_1h
            0, // sbp_2h
            0, // sbp_3h
            0, // sbp_4h
            0, // sbp_5h
            0, // sbp_6h
            0, // sbp_7h
            0, // sbp_8h
            0, // sbp_9h
            0, // sbp_10h
            0, // sbp_11h
            0, // sbp_12h
            0, // sbp_13h
            0, // sbp_14h
            0, // sbp_15h
            0, // sbp_16h
            0, // sbp_17h
            0, // sbp_18h
            0, // sbp_19h
            0, // sbp_20h
            0, // sbp_21h
            0, // sbp_22h
            0, // sbp_23h
            0, // sbp_24h
            0, // dbp_1h
            0, // dbp_2h
            0, // dbp_3h
            0, // dbp_4h
            0, // dbp_5h
            0, // dbp_6h
            0, // dbp_7h
            0, // dbp_8h
            0, // dbp_9h
            0, // dbp_10h
            0, // dbp_11h
            0, // dbp_12h
            0, // dbp_13h
            0, // dbp_14h
            0, // dbp_15h
            0, // dbp_16h
            0, // dbp_17h
            0, // dbp_18h
            0, // dbp_19h
            0, // dbp_20h
            0, // dbp_21h
            0, // dbp_22h
            0, // dbp_23h
            0, // dbp_24h
            0, // spo2_1h
            0, // spo2_2h
            0, // spo2_3h
            0, // spo2_4h
            0, // spo2_5h
            0, // spo2_6h
            0, // spo2_7h
            0, // spo2_8h
            0, // spo2_9h
            0, // spo2_10h
            0, // spo2_11h
            0, // spo2_12h
            0, // spo2_13h
            0, // spo2_14h
            0, // spo2_15h
            0, // spo2_16h
            0, // spo2_17h
            0, // spo2_18h
            0, // spo2_19h
            0, // spo2_20h
            0, // spo2_21h
            0, // spo2_22h
            0, // spo2_23h
            0, // spo2_24h
            30, // temperature_1h
            30, // temperature_2h
            30, // temperature_3h
            30, // temperature_4h
            30, // temperature_5h
            30, // temperature_6h
            30, // temperature_7h
            30, // temperature_8h
            30, // temperature_9h
            30, // temperature_10h
            30, // temperature_11h
            30, // temperature_12h
            30, // temperature_13h
            30, // temperature_14h
            30, // temperature_15h
            30, // temperature_16h
            30, // temperature_17h
            30, // temperature_18h
            30, // temperature_19h
            30, // temperature_20h
            30, // temperature_21h
            30, // temperature_22h
            30, // temperature_23h
            30, // temperature_24h
            10, // age
            0 // gender
        ]

        fetch(props.root + `/api/Vital/process_data`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                'data': data
            })
        })
            .then(result => result.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => error);
    }

    return <StyletronProvider value={props.engine}>
        <StyleReset/>
        <ThemeProvider>
            <Div>
                <Button
                    w="5.5rem" h="2.3rem" p={{x: "1rem"}} textSize="subheader" textColor="info600" hoverTextColor="info700" bg="white" hoverBg="info200"
                    border="2px solid" borderColor="info600" hoverBorderColor="info700" d="inline-block" onClick={send_data} textAlign={'center'}
                >Send</Button>
            </Div>

        </ThemeProvider>
    </StyletronProvider>
}

const exported_object = {Test}
export default exported_object;
export {Test}